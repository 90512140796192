import {
    SET_LOADING,
} from '../actions/types'

import LOADING_KEY from 'keys/loading.keys'

const INITIAL_STATE = {
    [LOADING_KEY.timeTap]: false,
};

const INITIAL_ACTION = { type: '' };

export default function(state=INITIAL_STATE, action=INITIAL_ACTION) {

    switch(action.type){

        case SET_LOADING:
            return {
                ...state,
                ...action.payload,
            };

        default:
            return state
    }

}
