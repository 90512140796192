import React, { Component, Fragment } from 'react'
import Main from './Main/Main'
import GlobalError from 'components/GlobalError'

class App extends Component {

    render() {
        return (
            <Fragment>
                <div className="app-body" style={{position: 'relative', zIndex: 2}}>
                    <Main />
                </div>

                <GlobalError />

            </Fragment>
        )
    }


}

export default App

