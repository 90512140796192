//Error Action Types
export const SET_ERROR = 'SET_ERROR';

// Form Action Types
export const INITIALIZE_FORM = 'INITIALIZE_FORM';
export const UPDATE_FORM_FIELD = 'UPDATE_FORM_FIELD';
export const VALIDATE_EDIT_FIELD = 'VALIDATE_EDIT_FIELD';
export const SET_FIELDS = 'SET_FIELDS';
export const SET_DIRTY = 'SET_DIRTY';
export const VALIDATE_ALL = 'VALIDATE_ALL';
export const CLEAR_FIELDS = 'CLEAR_FIELDS';
export const SET_SUBMITTING = 'SET_SUBMITTING';

//App Action Types
export const SET_LANGUAGE = 'SET_LANGUAGE';

//Loading Action Types
export const SET_LOADING = 'SET_LOADING';

//Show Language Button
export const SET_SHOW_LANG_BUTTON = 'SET_SHOW_LANG_BUTTON';
