export function getShow(hashed_show_id, specialist_id, timestamp, qrTime) {
    return function(dispatch, API){
        return API.show.fetchShow({ hashed_show_id, specialist_id, timestamp, qrTime }, process.env.SHOWTRACKER_ACCESS_KEY )
            .then(res => {
                return Promise.resolve(res.data);
            })
            .catch((e) => {
                console.log('Error fetching show:', e);
                return Promise.reject(e);
            })
    }
}
