import React from 'react'
import ReactGA from 'react-ga'

import {
    Switch,
    Route,
} from 'react-router-dom'

import {
    Form,
    NotFound,
    ThankYou,
    ErrorPage,
} from './lazy_routes';

ReactGA.initialize('UA-121886264-1');

const logPageView = () => {
    ReactGA.set({ page: window.location.hash });
    ReactGA.pageview(window.location.hash);
    return null;
};
export default (
    <div>
        <Route path="/" component={logPageView} />
        <Switch>

            <Route
                exact
                path="/"
                component={Form}
            />

            <Route
                exact
                path="/survey/:hashed_show_id/:specialist_id/:timestamp"
                component={Form}
            />

            <Route
                exact
                path="/oops"
                component={ErrorPage}
            />

            <Route
                exact
                path="/success"
                component={ThankYou}
            />

            <Route component={NotFound}/>

        </Switch>
    </div>
);
