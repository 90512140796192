exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._GlobalError-module__container___2pF_o{width:400px;position:fixed;bottom:0;left:-400px;background:#fdfeff}._GlobalError-module__container___2pF_o ._GlobalError-module__error___KCRjn{width:100%;position:relative;padding:15px 7px;border:2px solid #ccc;border-radius:0 3px 0 0;border-bottom:none;border-left:none;text-align:center}._GlobalError-module__container___2pF_o ._GlobalError-module__error___KCRjn ._GlobalError-module__close___1z5Zt{cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-size:15px;position:absolute;top:0;right:3px;color:#D62D0A}._GlobalError-module__container___2pF_o ._GlobalError-module__error___KCRjn ._GlobalError-module__close___1z5Zt::-moz-selection{background-color:transparent}._GlobalError-module__container___2pF_o ._GlobalError-module__error___KCRjn ._GlobalError-module__close___1z5Zt::selection{background-color:transparent}\n", ""]);

// exports
exports.locals = {
	"container": "_GlobalError-module__container___2pF_o",
	"error": "_GlobalError-module__error___KCRjn",
	"close": "_GlobalError-module__close___1z5Zt"
};