import { combineReducers } from 'redux'

import error from './error.reducer'
import form from './form.reducer'
import app from './app.reducer'
import loading from './loading.reducer'

export default combineReducers({
    error,
    form,
    app,
    loading,
});
