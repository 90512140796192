import style from './_Main.module.scss'
import React, { Component } from 'react'
import Routes from './routes';
const  iOS = navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
if (iOS) {
  document.head.querySelector('meta[name="viewport"]').content = 'width=device-width, initial-scale=1, maximum-scale=1';
}
class Main extends Component{
    constructor(props){
        super(props);
    }

    render(){

        return (
            <main className={style.container}>
                { Routes }
            </main>
        )
    }
}

export default Main;
