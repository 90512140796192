import leadConfig from './lead.config'

export const defaultFormValues = (form_id) => {
    switch(form_id){

        case 'leadForm':
            return leadConfig;

        default:
            return {}
    }
};
