import {
    SET_LANGUAGE,
    SET_SHOW_LANG_BUTTON,
} from '../actions/types'

const INITIAL_STATE = {language: 'en', showLangButton: true};
const INITIAL_ACTION = {type: '', payload: {}};

export default function(state=INITIAL_STATE, action=INITIAL_ACTION){
    switch(action.type){
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.payload,
            };
        case SET_SHOW_LANG_BUTTON:
            return {
                ...state,
                showLangButton: action.payload,
            }
    }
    return state;
}
