import ApiGroup from './ApiGroup'

export default class showApi extends ApiGroup {
    constructor(){
        super({
            name: 'show',
        });
    }

      fetchShow = (data, key) => (
          this.post({
              endpoint: `/fetch`,
              data,
          },
          {
              headers: {
                  'Authorization': key,
              },
          })
      );
}
