import React, { Fragment } from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import Bundle from '../../../utils/Bundle'

import loadForm from 'bundle-loader?lazy&name=[name]!pages/Form/Form.container'
import loadNotFound from 'bundle-loader?lazy&name=[name]!pages/NotFound/NotFound.page'
import loadThankYou from 'bundle-loader?lazy&name=[name]!pages/ThankYou/ThankYou.page'
import loadErrorPage from 'bundle-loader?lazy&name=[name]!pages/ErrorPage/Error.page'

export const Form = (props) => (
    <Bundle load={loadForm}>
        {(Form) => withCrumb(Form, props, '/', 'Home')}
    </Bundle>
);

export const ThankYou = (props) => (
    <Bundle load={loadThankYou}>
        {(ThankYou) => withCrumb(ThankYou, props, '/success', 'ThankYou')}
    </Bundle>
);

export const ErrorPage = (props) => (
    <Bundle load={loadErrorPage}>
        {(ErrorPage) => withCrumb(ErrorPage, props, '/oops', 'ErrorPage')}
    </Bundle>
);

export const NotFound = (props) => (
    <Bundle load={loadNotFound}>
        {(NotFound) => withCrumb(NotFound, props, '/not-found', 'NotFound')}
    </Bundle>
);


function withCrumb(Component, props, to, title){
    return (
        <Fragment>
            <BreadcrumbsItem to={to}>
                {title}
            </BreadcrumbsItem>
            <Component {...props}/>
        </Fragment>
    )
}
