import { SET_LANGUAGE,  SET_SHOW_LANG_BUTTON} from '../types'

export function setLanguage(payload){
    return {
        type: SET_LANGUAGE,
        payload,
    }
}

export function setShowLanguageButton(payload){
    return {
        type: SET_SHOW_LANG_BUTTON,
        payload,
    }
}
