import 'assets/to_import'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux';
import { ThroughProvider } from 'react-through';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import configureStore from './state/store';
import { unregister } from './utils/register_service_worker';

Bugsnag.start({
    apiKey: BUGSNAG_KEY,
    plugins: [ new BugsnagPluginReact() ],
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: [ 'production', 'staging' ],
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
const store = configureStore();
const rootElement = document.getElementById('root');

if (module.hot) {
    module.hot.accept('core/Root', () => {
        setTimeout(render, 0);
    });
}

render();
unregister();


function render(){
    const Root = require('core/Root').default;
    ReactDOM.render(
        <ThroughProvider>
            <ErrorBoundary>
                <Provider store={store}>
                    <Root />
                </Provider>
            </ErrorBoundary>
        </ThroughProvider>,
        rootElement
    );
}
