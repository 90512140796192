/*
* Try to keep in the same order as
* the form so it's easy to match up
*/

export const fieldKeys = [
    'show_id',
    'specialist_id',
    'device_survey_guid',
    'start_time',

    //Person Info
    'first_name',
    'last_name',
    // 'business_name',
    'address1',
    'address2',
    'city',
    'state',
    'zip_code',

    //Contact Info
    'phone',
    'email',

    //opt-ins
    'email_opt_in',
    'accepts_sms',

    //VOI
    'vehicles_of_interest',

    //Current Vehicle
    'vehicle_driven_most_make_id',

    //Acquire Next
    'in_market_timing',
    // Personal Or Business
    // 'personal_or_business',
    // 'industry',

];

export default {
    fieldKeys,
    defaultValues: {
        show_id: null,
        specialist_id: null,
        device_survey_guid: '',
        start_time: null,

        //Person Info
        first_name: '',
        last_name: '',
        business_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip_code: '',

        //Contact Info
        phone: '',
        email: '',

        //opt-ins
        email_opt_in: '',
        accepts_sms: '',

        //VOI
        vehicles_of_interest: [],

        //Current Vehicle
        vehicle_driven_most_make_id: '',

        //Acquire Next
        in_market_timing: '',
        // Personal Or Business
        // personal_or_business: '',
        // industry: '',
    },
};
