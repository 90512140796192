exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._Main-module__header___3-98v{left:50%;overflow:hidden;position:absolute;top:0;-webkit-transform:translateX(-50%);-ms-transform:translateX(-50%);transform:translateX(-50%);width:auto;z-index:-1}@media (min-width: 1200px){._Main-module__header___3-98v{width:100%}}._Main-module__footer___2nd41{bottom:0;position:absolute;width:auto;z-index:-1}@media (min-width: 1200px){._Main-module__footer___2nd41{width:100%}}\n", ""]);

// exports
exports.locals = {
	"header": "_Main-module__header___3-98v",
	"footer": "_Main-module__footer___2nd41"
};