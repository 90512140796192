import {
    INITIALIZE_FORM,
    UPDATE_FORM_FIELD,
    VALIDATE_EDIT_FIELD,
    SET_FIELDS,
    SET_DIRTY,
    VALIDATE_ALL,
    CLEAR_FIELDS,
} from '../actions/types'

const SET_SUBMITTING = 'SET_SUBMITTING';

import {
    defaultFormValues,
} from '../../form_configs'

const defaultFormState = {
    submitting: false,
    dirty: false,
    initialized: false,
    fields: {},
    errors: {},
};

const INITIAL_STATE = {
    leadForm: defaultFormState,
};

const INITIAL_ACTION = { type: '', payload: {} };

export default function(state=INITIAL_STATE, action=INITIAL_ACTION) {

    let form_id, DV;
    if (action.payload) {
        form_id = action.payload.form_id;
        DV = defaultFormValues(form_id);
    }

    switch (action.type) {

        case INITIALIZE_FORM:
            return {
                ...state,
                [form_id]: {
                    ...setDefaultValues(),
                    initialized: true,
                },
            };

        case SET_FIELDS:
            return {
                ...state,
                [form_id]: {
                    ...state[form_id],
                    fields: setInitialValues(action.payload.data),
                },
            };

        case SET_SUBMITTING:
            return {
                ...state,
                [form_id]: {
                    ...state[form_id],
                    submitting: action.payload.data,
                },
            };

        case SET_DIRTY:
            return {
                ...state,
                [form_id]: {
                    ...state[form_id],
                    dirty: action.payload.data,
                },
            };

        case UPDATE_FORM_FIELD:
            return {
                ...state,
                [form_id]: {
                    ...state[form_id],
                    dirty: true,
                    fields: updateField(state[form_id], action.payload.data),
                    errors: clearError(state[form_id], action.payload.data),
                },
            };


        case VALIDATE_EDIT_FIELD:
            return {
                ...state,
                [form_id]: {
                    ...state[form_id],
                    fields: updateField(state[form_id], action.payload.data),
                    errors: {
                        ...state[form_id].errors,
                        ...validateField(action.payload.data),
                    },
                },
            };


        case VALIDATE_ALL:
            return {
                ...state,
                [form_id]: {
                    ...state[form_id],
                    errors: validateAll(state[form_id]),
                },
            };


        case CLEAR_FIELDS:
            return {
                ...state,
                [form_id]: defaultFormState,
            };

        default:
            return state
    }

    // Helper Functions
    // ————————————————

    function setDefaultValues(){
        const fields = {};
        const errors = {};

        DV.fieldKeys.forEach(field => {
            fields[field] = DV.defaultValues[field];
            errors[field] = '';
        });

        return {
            fields,
            errors,
        }
    }

    function setInitialValues(payload) {
        const fields = {};
        DV.fieldKeys.forEach(key => {

            fields[key] = (
                payload[key] !== null && payload[key] !== ''
                    ? payload[key]
                    : DV.defaultValues[key]
            )
        });

        return fields
    }

    function updateField(state, payload) {
        return {
            ...state.fields,
            [payload.key]: (
                payload.value !== undefined
                    ? payload.value
                    : DV.defaultValues[payload.key] || null
            ),
        }
    }

    function validateAll(state){
        return {
            ...state.errors,
            ...DV.validate(state.fields),
        }
    }

    function clearError(state, payload){
        const cleanErrors = {};
        if(state.errors[payload.key] && payload.value){
            const newError = validateField(payload)[payload.key];
            if(newError === undefined){
                cleanErrors[payload.key] = ''
            }
        }
        return {
            ...state.errors,
            ...cleanErrors,
        }
    }

    function validateField(payload){
        return DV.validate({
            [payload.key]: payload.value,
        })
    }
}
